<template>
  <v-row justify="center">
    <v-col cols="12" sm="8" md="5" lg="5">
      <v-card>
        <v-img
          class="mx-auto"
          contain
          width="150"
          src="https://ik.imagekit.io/vconexID/Property_Expo/assets/Logo_Rumah-01.png"
        ></v-img>
        <v-card-text>
          <v-form @submit.prevent="submit">
            <v-text-field
              v-model="form.username"
              label="Username"
              prepend-inner-icon="mdi-account"
              outlined
              :disabled="loading"
              :error-messages="errors.username"
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              label="Password"
              :type="type"
              append-icon="mdi-eye"
              @click:append="changeType"
              :disabled="loading"
              :error-messages="errors.password"
              prepend-inner-icon="mdi-key-variant"
              outlined
              dense
            ></v-text-field>
            <div class="text-right">
              <v-btn
                color="primary"
                block
                depressed
                :loading="loading"
                type="submit"
                class="text-capitalize"
                dark
              >
                <v-icon class="mr-2" small>mdi-login</v-icon>
                masuk
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { login } from "@/api/auth";
import Swal from "sweetalert2";
import form from "@/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      type: "password",
    };
  },
  methods: {
    changeType() {
      this.type === "password"
        ? (this.type = "text")
        : (this.type = "password");
    },
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        const { data } = await login(this.form);
        if(data.user.role_id === 3){
          this.$store.dispatch("auth/setLoading");
          Swal.fire({
            icon: "error",
            title: "Maaf, Anda bukan admin",
            timer: 1500,
          })
        }else{

          const payload = { token: data.access_token, profile: data.user };
          this.$store.dispatch("auth/setLoading");
          this.errors = {};
          this.$store.dispatch("auth/saveAuth", payload);
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          console.log(error.response);
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
