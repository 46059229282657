<template>
  <v-container class="fill-height">
    <login-form></login-form>
  </v-container>
</template>

<script>
import LoginForm from "../components/LoginForm";

export default {
  components: {
    LoginForm,
  },
};
</script>

<style></style>
